import React from "react"
import { graphql, Link } from "gatsby"
import Tag from "../components/Tag/Tag"
import { Fragment } from "react"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark

  const year = new Date().getFullYear();

  return (
    <Fragment>
      <head>
        <title>{frontmatter.title}</title>
        <meta name="description" content={frontmatter.subtitle} />
        <meta name="keywords" content={frontmatter.keywords}></meta>
      </head>
      <header className="mainHeader">
        <span className="homeLink"><Link to="/">Home</Link></span>
      </header>
      <div className="mainContainer">
        <div className="centeredReadableContainer">
          <article className="blogPost">
            <h1>{frontmatter.title}</h1>
            <div style={{ fontSize: "1em", textAlign: "center" }}>written by Ryan Schalk</div>
            <div style={{ fontSize: "0.8em", textAlign: "center" }}>{frontmatter.date}</div>
            <div className="tagsContainer">
            {frontmatter.tags.map((element, i) => {
              return <Tag text={element} key={i} />
            })}
            </div>
            <hr />
            <div
              className="blog-post-content"
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </article>
        </div>
      </div>
      <footer>&copy; {year} Ryan Schalk</footer>
    </Fragment>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        subtitle
        tags
        keywords
      }
    }
  }
`
